import axios from "axios";
import * as React from "react";
import { FC, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { SessionSpeakerProps } from "./SessionSpeaker";

const AVATAR_SIZE = 160;

interface SpeakerModalProps {
  data: SessionSpeakerProps;
  onHide: () => void;
}

interface SpeakerApiResponse {
  id: number;
  avatar: string;
  description: string;
  fullName: string;
  title: string;
  shortProfile: string;
}

const SpeakerModal: FC<SpeakerModalProps> = ({ data, onHide }) => {
  const [info, setInfo] = useState<{
    title: string;
    description: string;
    shortProfile: string;
  }>();

  useEffect(() => {
    if (data !== null) {
      axios
        .get<SpeakerApiResponse>(`/api/faculty_members/${data.id}`)
        .then((res) => {
          setInfo({
            title: res.data.title,
            description: res.data.description,
            shortProfile: res.data.shortProfile,
          });
        });
    }
  }, [data]);

  return (
    <Modal show={data !== null} onHide={onHide} size="lg" centered>
      <Modal.Body className="text-center py-5">
        <div
          className="speaker-image mb-2 img-fluid mx-auto rounded-circle"
          style={{
            backgroundImage: `url("${data?.avatar}")`,
            width: `${AVATAR_SIZE}px`,
            height: `${AVATAR_SIZE}px`,
          }}
        />
        {/* <img
          src={data?.avatar}
          className="rounded-circle me-2"
          height={AVATAR_SIZE}
          width={AVATAR_SIZE}
        /> */}
        <h5 className="mt-3 text-primary">{data?.fullName}</h5>
        {info && (
          <>
            {info.title && (
              <h6 className="text-muted fw-500 my-2">{info.title}</h6>
            )}
            {info.shortProfile && (
              <h6 className="text-muted fw-500 fst-italic">
                {info.shortProfile}
              </h6>
            )}
            {info.description && (
              <p
                className="speaker-desc px-md-3 fw-500 my-3 text-justify"
                dangerouslySetInnerHTML={{ __html: info.description }}
              ></p>
              // <p className="speaker-desc px-md-3 fw-500 my-3 text-justify">{info.description}</p>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SpeakerModal;
